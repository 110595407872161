var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('div', {
    staticStyle: {
      "height": "70vh",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : !_vm.cliente || _vm.error ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v(" " + _vm._s(_vm.error || "No se encontro data del cliente") + " ")])], 1)])], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Datos del cliente #".concat(_vm.clientID)
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-first-name"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-first-name",
      "placeholder": "Nombre"
    },
    model: {
      value: _vm.cliente.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "nombre", $$v);
      },
      expression: "cliente.nombre"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Apellido",
      "label-for": "vi-last-name"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-last-name",
      "placeholder": "Apellido"
    },
    model: {
      value: _vm.cliente.apellido,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "apellido", $$v);
      },
      expression: "cliente.apellido"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "vi-email"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-email",
      "type": "email",
      "placeholder": "Email"
    },
    model: {
      value: _vm.cliente.email,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "email", $$v);
      },
      expression: "cliente.email"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Telefono",
      "label-for": "vi-mobile"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SmartphoneIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "Telefono"
    },
    model: {
      value: _vm.cliente.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "telefono", $$v);
      },
      expression: "cliente.telefono"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo",
      "label-for": "vi-id-type"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-id-type",
      "label": "title",
      "reduce": function reduce(t) {
        return t.code;
      },
      "options": _vm.tiposIdentificacion,
      "clearable": false
    },
    model: {
      value: _vm.cliente.tipoidentifica,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "tipoidentifica", $$v);
      },
      expression: "cliente.tipoidentifica"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Identificacion",
      "label-for": "vi-id"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LockIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-id",
      "type": "number",
      "placeholder": "Identificacion"
    },
    model: {
      value: _vm.cliente.identificacion,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "identificacion", $$v);
      },
      expression: "cliente.identificacion"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rol",
      "label-for": "vi-rol"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-rol",
      "label": "title",
      "reduce": function reduce(r) {
        return r.code;
      },
      "options": _vm.roles,
      "clearable": false
    },
    model: {
      value: _vm.cliente.rol,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "rol", $$v);
      },
      expression: "cliente.rol"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estado",
      "label-for": "vi-status"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-status",
      "label": "title",
      "reduce": function reduce(e) {
        return e.code;
      },
      "options": _vm.estados,
      "clearable": false
    },
    model: {
      value: _vm.cliente.estado,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "estado", $$v);
      },
      expression: "cliente.estado"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Nacimiento",
      "label-for": "vi-fecha"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "vi-fecha",
      "min": _vm.min,
      "max": _vm.max,
      "locale": "es",
      "placeholder": "Fecha Nacimiento",
      "date-format-options": {
        day: 'numeric',
        year: 'numeric',
        month: 'long'
      }
    },
    model: {
      value: _vm.cliente.fchNacimientoParsed,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "fchNacimientoParsed", $$v);
      },
      expression: "cliente.fchNacimientoParsed"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.saving ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-card', [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.confirmAddToWhitelist
    }
  }, [_vm.savingWhitelist ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v(" " + _vm._s(_vm.cliente.inWhitelist ? "Eliminar del Whitelist" : "Agregar al Whitelist") + " ")])], 1), _c('b-button', {
    attrs: {
      "block": "",
      "variant": "outline-danger"
    },
    on: {
      "click": _vm.confirmAddToBlacklist
    }
  }, [_vm.savingBlacklist ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v(" " + _vm._s(_vm.cliente.inBlacklist ? "Eliminar del Blacklist" : "Agregar al Blacklist") + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }