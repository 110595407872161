<template>
  <div
    v-if="loading"
    style="
      height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <b-spinner label="Loading..." />
  </div>
  <div v-else-if="!cliente || error">
    <b-alert show variant="danger">
      <div class="alert-body">
        <feather-icon class="mr-25" icon="AlertTriangleIcon" />
        <span class="ml-25">
          {{ error || "No se encontro data del cliente" }}
        </span>
      </div>
    </b-alert>
  </div>
  <b-row v-else>
    <b-col cols="12" md="8">
      <b-card :title="`Datos del cliente #${clientID}`">
        <b-row>
          <!-- first name -->
          <b-col cols="12" md="6">
            <b-form-group label="Nombre" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-first-name"
                  v-model="cliente.nombre"
                  placeholder="Nombre"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- last name -->
          <b-col cols="12" md="6">
            <b-form-group label="Apellido" label-for="vi-last-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-last-name"
                  v-model="cliente.apellido"
                  placeholder="Apellido"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="12" md="6">
            <b-form-group label="Email" label-for="vi-email">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-email"
                  type="email"
                  v-model="cliente.email"
                  placeholder="Email"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- mobile -->
          <b-col cols="12" md="6">
            <b-form-group label="Telefono" label-for="vi-mobile">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SmartphoneIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-mobile"
                  type="number"
                  v-model="cliente.telefono"
                  placeholder="Telefono"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- id type-->
          <b-col cols="12" md="6">
            <b-form-group label="Tipo" label-for="vi-id-type">
              <v-select
                id="vi-id-type"
                label="title"
                v-model="cliente.tipoidentifica"
                :reduce="(t) => t.code"
                :options="tiposIdentificacion"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- id -->
          <b-col cols="12" md="6">
            <b-form-group label="Identificacion" label-for="vi-id">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-id"
                  type="number"
                  v-model="cliente.identificacion"
                  placeholder="Identificacion"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Role -->
          <b-col cols="12" md="6">
            <b-form-group label="Rol" label-for="vi-rol">
              <v-select
                id="vi-rol"
                label="title"
                v-model="cliente.rol"
                :reduce="(r) => r.code"
                :options="roles"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- Status -->
          <b-col cols="12" md="6">
            <b-form-group label="Estado" label-for="vi-status">
              <v-select
                id="vi-status"
                label="title"
                v-model="cliente.estado"
                :reduce="(e) => e.code"
                :options="estados"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- Birthdate -->
          <b-col cols="12" md="6">
            <b-form-group label="Fecha Nacimiento" label-for="vi-fecha">
              <b-form-datepicker
                id="vi-fecha"
                :min="min"
                :max="max"
                locale="es"
                v-model="cliente.fchNacimientoParsed"
                placeholder="Fecha Nacimiento"
                :date-format-options="{
                  day: 'numeric',
                  year: 'numeric',
                  month: 'long',
                }"
              />
            </b-form-group>
          </b-col>

          <!-- submit -->
          <b-col cols="12">
            <b-button class="mr-1" @click="saveData" variant="primary">
              <b-spinner v-if="saving" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="12" md="4">
      <b-card>
        <b-button
          block
          @click="confirmAddToWhitelist"
          variant="outline-secondary"
        >
          <b-spinner v-if="savingWhitelist" small label="Loading..." />
          <div v-else>
            {{
              cliente.inWhitelist
                ? "Eliminar del Whitelist"
                : "Agregar al Whitelist"
            }}
          </div>
        </b-button>
        <b-button block @click="confirmAddToBlacklist" variant="outline-danger">
          <b-spinner v-if="savingBlacklist" small label="Loading..." />
          <div v-else>
            {{
              cliente.inBlacklist
                ? "Eliminar del Blacklist"
                : "Agregar al Blacklist"
            }}
          </div>
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    vSelect,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupPrepend,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    clientID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      min: new Date(1960, 1, 1),
      max: new Date(),
      error: null,
      saving: false,
      cliente: null,
      loading: true,
      savingWhitelist: false,
      savingBlacklist: false,
      roles: [
        { title: "Cliente", code: "C" },
        { title: "Super Admin", code: "SA" },
        { title: "Administrador", code: "AD" },
        { title: "Cajero", code: "CA" },
        { title: "Call Center", code: "CC" },
        { title: "Gerente", code: "GE" },
        { title: "Super Gerente", code: "SG" },
        { title: "Supervisor", code: "SU" },
        { title: "Despachador", code: "DE" },
      ],
      estados: [
        { title: "Activo", code: "A" },
        { title: "Inactivo", code: "I" },
        { title: "Eliminado", code: "E" },
      ],
      tiposIdentificacion: [
        { title: "Cedula", code: "C" },
        { title: "Pasaporte", code: "P" },
        { title: "RUC", code: "R" },
      ],
    };
  },
  mounted() {
    this.getClientData();
  },
  methods: {
    getClientData() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/datos",
          body: JSON.stringify({
            id: this.clientID,
            opt: "I",
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.cliente = res.data;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.error = e.message;
          this.loading = false;
        });
    },
    saveData() {
      this.saving = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/upd",
          body: JSON.stringify({
            id: this.cliente.id,
            rol: this.cliente.rol,
            email: this.cliente.email,
            nombre: this.cliente.nombre,
            genero: this.cliente.genero,
            estado: this.cliente.estado,
            empresa: this.cliente.empresa,
            apellido: this.cliente.apellido,
            telefono: this.cliente.telefono,
            tokenSesion: getAuthToken(),
            estado_civil: this.cliente.estado_civil,
            fchNacimiento: this.cliente.fchNacimientoParsed,
            tipoidentifica: this.cliente.tipoidentifica,
            identificacion: this.cliente.identificacion,
          }),
        })
        .then((res) => {
          this.saving = false;
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "BellIcon",
                text: `Se guardo los cambios correctamente`,
                title: "Exito",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al guardar los cambios (${res.data.mensaje}).`,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al guardar los cambios (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
          });
          this.saving = false;
        });
    },
    confirmAddToWhitelist(e) {
      e && e.preventDefault();
      this.$bvModal
        .msgBoxConfirm(
          this.cliente.inWhitelist
            ? "Desea Eliminar el cliente del Whitelist?."
            : "Desea Agregar el cliente al Whitelist?.",
          {
            size: "sm",
            title: "Confirmacion",
            okTitle: this.cliente.inWhitelist ? "Eliminar" : "Agregar",
            centered: true,
            okVariant: this.cliente.inWhitelist ? "warning" : "primary",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
          }
        )
        .then((value) => {
          if (value) {
            this.toggleWhitelist(this.cliente.inWhitelist);
          }
        });
    },
    confirmAddToBlacklist(e) {
      e && e.preventDefault();
      this.$bvModal
        .msgBoxConfirm(
          this.cliente.inBlacklist
            ? "Desea eliminar el cliente del Blacklist?."
            : "Desea agregar el cliente al Blacklist?.",
          {
            size: "sm",
            title: "Confirmacion",
            okTitle: this.cliente.inBlacklist ? "Eliminar" : "Agregar",
            centered: true,
            okVariant: "danger",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
          }
        )
        .then((value) => {
          if (value) {
            this.toggleBlacklist(this.cliente.inBlacklist);
          }
        });
    },
    toggleWhitelist(inWhitelist) {
      this.savingWhitelist = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: inWhitelist
            ? "/Customer/whitelist/del"
            : "/Customer/whitelist/add",
          body: JSON.stringify({
            empresa: 2,
            idCliente: this.clientID,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.savingWhitelist = false;
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "BellIcon",
                text: inWhitelist
                  ? "El cliente se elimino correctamente del Whitelist"
                  : "El cliente se agrego correctamente al Whitelist",
                title: "Exito",
              },
            });
            this.cliente.inWhitelist = !inWhitelist;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: inWhitelist
                  ? `Ocurrio un error al eliminar del Whitelist (${res.data.mensaje}).`
                  : `Ocurrio un error al agregar a Whitelist (${res.data.mensaje}).`,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: inWhitelist
                ? `Ocurrio un error al eliminar del Whitelist (${e.message}).`
                : `Ocurrio un error al agregar a Whitelist (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
          });
          this.savingWhitelist = false;
        });
    },
    toggleBlacklist(inBlacklist) {
      this.saving = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: inBlacklist
            ? "/Customer/blacklist/del"
            : "/Customer/blacklist/add",
          body: JSON.stringify({
            empresa: 2,
            idCliente: this.clientID,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.saving = false;
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "BellIcon",
                text: inBlacklist
                  ? "El cliente se elimino correctamente del Blacklist"
                  : "El cliente se agrego correctamente al Blacklist",
                title: "Exito",
              },
            });
            this.cliente.inBlacklist = !inBlacklist;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: inBlacklist
                  ? `Ocurrio un error al eliminar el cliente del Blacklist (${res.data.mensaje}).`
                  : `Ocurrio un error al agregar el cliente al Blacklist (${res.data.mensaje}).`,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: inBlacklist
                ? `Ocurrio un error al eliminar el cliente del Blacklist (${e.message}).`
                : `Ocurrio un error al agregar a Blacklist (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
          });
          this.saving = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>