var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "pills": "",
      "vertical": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "General",
      "active": ""
    }
  }, [_c('tab-general', {
    attrs: {
      "clientID": _vm.clientID
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Direcciones",
      "lazy": ""
    }
  }, [_c('tab-direcciones', {
    attrs: {
      "clientID": _vm.clientID
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Facturacion",
      "lazy": ""
    }
  }, [_c('tab-facturacion', {
    attrs: {
      "clientID": _vm.clientID
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Password",
      "lazy": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Cambiar Contrase\xF1a del cliente #".concat(_vm.clientID)
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Contraseña de administrador",
      "label-for": "vi-admin-pass"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-admin-pass",
      "type": "password",
      "state": _vm.errorAdminPass === null ? null : false,
      "placeholder": "Contraseña de administrador",
      "autocomplete": "off"
    },
    on: {
      "input": _vm.onAdminPassChange
    },
    model: {
      value: _vm.adminPass,
      callback: function callback($$v) {
        _vm.adminPass = $$v;
      },
      expression: "adminPass"
    }
  }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(_vm.errorAdminPass) + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Contraseña Nueva",
      "label-for": "vi-client-pass"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-client-pass",
      "type": "password",
      "state": _vm.errorNewPass === null ? null : false,
      "placeholder": "Contraseña Nueva",
      "autocomplete": "off"
    },
    on: {
      "input": _vm.onNewPassChange
    },
    model: {
      value: _vm.newUserPass,
      callback: function callback($$v) {
        _vm.newUserPass = $$v;
      },
      expression: "newUserPass"
    }
  }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(_vm.errorNewPass) + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Confirmar contraseña",
      "label-for": "vi-client-pass-confirm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-client-pass-confirm",
      "type": "password",
      "state": _vm.errorConfirmPass === null ? null : false,
      "placeholder": "Confirmar contraseña",
      "autocomplete": "off"
    },
    on: {
      "input": _vm.onConfirmPassChange
    },
    model: {
      value: _vm.confirmUserPass,
      callback: function callback($$v) {
        _vm.confirmUserPass = $$v;
      },
      expression: "confirmUserPass"
    }
  }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(_vm.errorConfirmPass) + " ")])], 1), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.saving
    },
    on: {
      "click": _vm.cambiarPass
    }
  }, [_vm.saving ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticStyle: {
      "text-align": "center",
      "margin": "10vh 0"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "KeyIcon",
      "size": "100"
    }
  })], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }