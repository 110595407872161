var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Direcciones del cliente #".concat(_vm.clientID)
    }
  }, [_c('vue-good-table', {
    attrs: {
      "rows": _vm.direcciones,
      "columns": _vm.columns,
      "isLoading": _vm.loading
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.loading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.loading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "no-caret": "",
            "toggle-class": "text-decoration-none"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.editLocation($event, props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Editar")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.confirmDeleteLocation($event, props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Eliminar")])], 1)], 1)], 1)]) : props.column.field === 'predeterminada' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.defaultVariant(props.row.predeterminada)
          }
        }, [_vm._v(" " + _vm._s(_vm.defaultText(props.row.predeterminada)) + " ")])], 1) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_vm.selectedLocation.id ? _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "bg-light-primary rounded-top text-center"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.urlMaps,
      "alt": "Meeting Pic",
      "height": "170",
      "fluid": ""
    }
  })], 1), _c('b-card-body', [_c('b-form-group', {
    attrs: {
      "label": "Descripcion",
      "label-for": "vi-description"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BookmarkIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-description",
      "placeholder": "Descripcion"
    },
    model: {
      value: _vm.selectedLocation.descripcion,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedLocation, "descripcion", $$v);
      },
      expression: "selectedLocation.descripcion"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Direccion",
      "label-for": "vi-location"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MapIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-location",
      "placeholder": "Direccion"
    },
    model: {
      value: _vm.selectedLocation.direccion,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedLocation, "direccion", $$v);
      },
      expression: "selectedLocation.direccion"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Detalles",
      "label-for": "vi-details"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MapPinIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-details",
      "placeholder": "Detalles"
    },
    model: {
      value: _vm.selectedLocation.detalle,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedLocation, "detalle", $$v);
      },
      expression: "selectedLocation.detalle"
    }
  })], 1)], 1), _c('b-button', {
    attrs: {
      "block": "",
      "disabled": "",
      "variant": "primary"
    }
  }, [_vm._v(" Guardar ")])], 1)], 1) : _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin": "10vh 0"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MapIcon",
      "size": "100"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }