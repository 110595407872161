<template>
  <b-tabs pills vertical>
    <b-tab title="General" active>
      <tab-general :clientID="clientID" />
    </b-tab>
    <b-tab title="Direcciones" lazy>
      <tab-direcciones :clientID="clientID" />
    </b-tab>
    <b-tab title="Facturacion" lazy>
      <tab-facturacion :clientID="clientID" />
    </b-tab>
    <b-tab title="Password" lazy>
      <b-row>
        <b-col cols="12" md="6">
          <b-card :title="`Cambiar Contraseña del cliente #${clientID}`">
            <!-- User pass -->
            <b-form-group
              label="Contraseña de administrador"
              label-for="vi-admin-pass"
            >
              <b-form-input
                id="vi-admin-pass"
                type="password"
                @input="onAdminPassChange"
                :state="errorAdminPass === null ? null : false"
                v-model="adminPass"
                placeholder="Contraseña de administrador"
                autocomplete="off"
              />
              <b-form-invalid-feedback>
                {{ errorAdminPass }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- Client pass -->
            <b-form-group label="Contraseña Nueva" label-for="vi-client-pass">
              <b-form-input
                id="vi-client-pass"
                type="password"
                @input="onNewPassChange"
                :state="errorNewPass === null ? null : false"
                v-model="newUserPass"
                placeholder="Contraseña Nueva"
                autocomplete="off"
              />
              <b-form-invalid-feedback>
                {{ errorNewPass }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- Client pass confirm -->
            <b-form-group
              label="Confirmar contraseña"
              label-for="vi-client-pass-confirm"
            >
              <b-form-input
                id="vi-client-pass-confirm"
                type="password"
                @input="onConfirmPassChange"
                :state="errorConfirmPass === null ? null : false"
                v-model="confirmUserPass"
                placeholder="Confirmar contraseña"
                autocomplete="off"
              />
              <b-form-invalid-feedback>
                {{ errorConfirmPass }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-button
              block
              class="mr-1"
              variant="primary"
              @click="cambiarPass"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="12" md="6">
          <div style="text-align: center; margin: 10vh 0">
            <feather-icon icon="KeyIcon" size="100" />
          </div>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  BTab,
  BRow,
  BCol,
  BTabs,
  BCard,
  BButton,
  BSpinner,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import TabGeneral from "./components/tabGeneral.vue";
import TabDirecciones from "./components/tabDirecciones.vue";
import TabFacturacion from "./components/tabFacturacion.vue";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BTabs,
    BCard,
    BButton,
    BSpinner,
    TabGeneral,
    BFormInput,
    BFormGroup,
    BInputGroup,
    TabDirecciones,
    TabFacturacion,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    ToastificationContent,
  },
  data() {
    return {
      saving: false,
      clientID: 0,
      adminPass: "",
      newUserPass: "",
      errorNewPass: null,
      errorAdminPass: null,
      confirmUserPass: "",
      errorConfirmPass: null,
    };
  },
  beforeMount() {
    this.clientID = parseInt(this.$route.path.replace("/cliente/", ""));
  },
  methods: {
    onNewPassChange() {
      if (this.errorNewPass !== null) this.errorNewPass = null;
    },
    onAdminPassChange() {
      if (this.errorAdminPass !== null) this.errorAdminPass = null;
    },
    onConfirmPassChange() {
      if (this.errorConfirmPass !== null) this.errorConfirmPass = null;
    },
    cambiarPass(event) {
      event.preventDefault();
      if (this.newUserPass.length < 6) {
        this.errorNewPass = "La contraseña debe ser mayor a 6 caracteres";
      } else if (this.newUserPass !== this.confirmUserPass) {
        this.errorNewPass = "La contraseñas deben conincidir";
        this.errorConfirmPass = "La contraseñas deben conincidir";
      } else {
        this.saving = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/CambioClaveCliente",
            body: JSON.stringify({
              IDCliente: this.clientID,
              tokenSesion: getAuthToken(),
              ClaveUsuario: this.adminPass,
              ClaveNuevaCliente: this.newUserPass,
            }),
          })
          .then((res) => {
            this.saving = false;
            if (res.data.bOk) {
              this.$toast({
                props: {
                  icon: "BellIcon",
                  text: `Se cambio correctamente la contraseña del cliente`,
                  title: "Exito",
                },
                component: ToastificationContent,
              });
            } else {
              if (res.data.mensaje === "La clave del Usuario es Invalida") {
                this.errorAdminPass = "La clave ingresada es Invalida";
                this.$toast({
                  props: {
                    icon: "AlertTriangleIcon",
                    text: "La clave ingresada del Usuario administrador es Invalida",
                    title: "Error",
                    variant: "danger",
                  },
                  component: ToastificationContent,
                });
              } else {
                this.$toast({
                  props: {
                    icon: "AlertTriangleIcon",
                    text: `Ocurrio un error al cambiar la contraseña del cliente (${res.data.mensaje}).`,
                    title: "Error",
                    variant: "danger",
                  },
                  component: ToastificationContent,
                });
              }
            }
          })
          .catch((e) => {
            this.$toast({
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al cambiar la contraseña del cliente (${e.message}).`,
                title: "Error",
                variant: "danger",
              },
              component: ToastificationContent,
            });
            this.saving = false;
          });
      }
    },
  },
};
</script>
