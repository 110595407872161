<template>
  <b-row>
    <b-col cols="12" md="7">
      <b-card :title="`Direcciones del cliente #${clientID}`">
        <!-- table -->
        <vue-good-table
          :rows="direcciones"
          :columns="columns"
          :isLoading.sync="loading"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="text-decoration-none"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="editLocation($event, props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="confirmDeleteLocation($event, props.row)"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Eliminar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Predeterminado -->
            <span v-else-if="props.column.field === 'predeterminada'">
              <b-badge :variant="defaultVariant(props.row.predeterminada)">
                {{ defaultText(props.row.predeterminada) }}
              </b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
    <b-col cols="12" md="5">
      <b-card v-if="selectedLocation.id" no-body>
        <div class="bg-light-primary rounded-top text-center">
          <b-img :src="urlMaps" alt="Meeting Pic" height="170" fluid />
        </div>

        <b-card-body>
          <!-- description -->

          <b-form-group label="Descripcion" label-for="vi-description">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="BookmarkIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-description"
                v-model="selectedLocation.descripcion"
                placeholder="Descripcion"
              />
            </b-input-group>
          </b-form-group>

          <!-- location -->
          <b-form-group label="Direccion" label-for="vi-location">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="MapIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-location"
                v-model="selectedLocation.direccion"
                placeholder="Direccion"
              />
            </b-input-group>
          </b-form-group>

          <!-- details -->
          <b-form-group label="Detalles" label-for="vi-details">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="MapPinIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-details"
                v-model="selectedLocation.detalle"
                placeholder="Detalles"
              />
            </b-input-group>
          </b-form-group>
          <b-button block disabled variant="primary"> Guardar </b-button>
        </b-card-body>
      </b-card>
      <div v-else style="text-align: center; margin: 10vh 0">
        <feather-icon icon="MapIcon" size="100" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BBadge,
  BButton,
  BCardBody,
  BDropdown,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BDropdownItem,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BDropdown,
    BCardBody,
    BFormGroup,
    BFormInput,
    BInputGroup,
    VueGoodTable,
    BDropdownItem,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  props: {
    clientID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      columns: [
        {
          label: "Descripcion",
          field: "descripcion",
        },
        {
          label: "En uso",
          field: "predeterminada",
        },
        {
          label: "Acciones",
          field: "action",
        },
      ],
      searchTerm: "",
      direcciones: [],
      selectedLocation: {},
    };
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    editLocation(event, location) {
      event && event.preventDefault();
      this.selectedLocation = location;
    },
    confirmDeleteLocation(event, location) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Desea eliminar la direccion del cliente?.", {
          size: "sm",
          title: "Confirmacion",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) this.deleteLocation(location);
        });
    },
    getLocations() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/Direccion",
          body: JSON.stringify({
            empresa: 2,
            cliente: this.clientID,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.direcciones = res.data.direcciones ? res.data.direcciones : [];
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.error = e.message;
          this.loading = false;
        });
    },
    deleteLocation(location) {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UserStores", //TODO: Implementar API
          body: JSON.stringify({
            clienteID: location.id,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se elimino correctamente la direccion`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
          } else {
            this.$toast({
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al eliminar la direccion (${res.data.mensaje}).`,
                title: "Error",
                variant: "danger",
              },
              component: ToastificationContent,
            });
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al eliminar la direccion (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        });
    },
  },
  computed: {
    urlMaps() {
      return `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyC74bqlZO6_QbiEmCMFIaMQ8Mrv37qo3Xs&zoom=18&size=1000x300&center=${this.selectedLocation.latitud}%2C${this.selectedLocation.longitud}&markers=color%3Ared%7C${this.selectedLocation.latitud}%2C${this.selectedLocation.longitud}`;
    },
    defaultText() {
      const status = {
        S: "Si",
        N: "No",
      };
      return (s) => status[s];
    },
    defaultVariant() {
      const statusColor = {
        S: "light-success",
        N: "light-secondary",
      };
      return (s) => statusColor[s];
    },
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
