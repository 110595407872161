var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Datos de facturacion del cliente #".concat(_vm.clientID)
    }
  }, [_c('vue-good-table', {
    attrs: {
      "rows": _vm.cabeceras,
      "columns": _vm.columns,
      "isLoading": _vm.loading
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.loading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.loading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.editBilling($event, props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Editar")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.confirmDeleteBilling($event, props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Eliminar")])], 1)], 1)], 1)]) : props.column.field === 'predeterminada' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.defaultVariant(props.row.predeterminada)
          }
        }, [_vm._v(" " + _vm._s(_vm.defaultText(props.row.predeterminada)) + " ")])], 1) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [!_vm.selectedBilling.id ? _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin": "10vh 0"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BookOpenIcon",
      "size": "100"
    }
  })], 1) : _c('b-card', {
    attrs: {
      "title": "Detalles"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre completo",
      "label-for": "vi-first-name"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-first-name",
      "placeholder": "Nombre completo"
    },
    model: {
      value: _vm.selectedBilling.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedBilling, "nombre", $$v);
      },
      expression: "selectedBilling.nombre"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "vi-email"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-email",
      "type": "email",
      "placeholder": "Email"
    },
    model: {
      value: _vm.selectedBilling.correo,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedBilling, "correo", $$v);
      },
      expression: "selectedBilling.correo"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Telefono",
      "label-for": "vi-mobile"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SmartphoneIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "Telefono"
    },
    model: {
      value: _vm.selectedBilling.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedBilling, "telefono", $$v);
      },
      expression: "selectedBilling.telefono"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo",
      "label-for": "vi-id-type"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-id-type",
      "label": "title",
      "reduce": function reduce(t) {
        return t.code;
      },
      "options": _vm.tiposIdentificacion,
      "clearable": false
    },
    model: {
      value: _vm.selectedBilling.tipoidentifica,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedBilling, "tipoidentifica", $$v);
      },
      expression: "selectedBilling.tipoidentifica"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Identificacion",
      "label-for": "vi-id"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LockIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-id",
      "type": "number",
      "placeholder": "Identificacion"
    },
    model: {
      value: _vm.selectedBilling.identificacion,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedBilling, "identificacion", $$v);
      },
      expression: "selectedBilling.identificacion"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estado",
      "label-for": "vi-status"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-status",
      "label": "title",
      "reduce": function reduce(e) {
        return e.code;
      },
      "options": _vm.estados,
      "clearable": false
    },
    model: {
      value: _vm.selectedBilling.estado,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedBilling, "estado", $$v);
      },
      expression: "selectedBilling.estado"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "block": "",
      "variant": "primary"
    }
  }, [_vm.saving ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }