<template>
  <b-row>
    <b-col cols="12" md="7">
      <b-card :title="`Datos de facturacion del cliente #${clientID}`">
        <!-- table -->
        <vue-good-table
          :rows="cabeceras"
          :columns="columns"
          :isLoading.sync="loading"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="editBilling($event, props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="confirmDeleteBilling($event, props.row)"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Eliminar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Predeterminado -->
            <span v-else-if="props.column.field === 'predeterminada'">
              <b-badge :variant="defaultVariant(props.row.predeterminada)">
                {{ defaultText(props.row.predeterminada) }}
              </b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
    <b-col cols="12" md="5">
      <div
        v-if="!selectedBilling.id"
        style="text-align: center; margin: 10vh 0"
      >
        <feather-icon icon="BookOpenIcon" size="100" />
      </div>
      <b-card v-else title="Detalles">
        <b-row>
          <!-- Name -->
          <b-col cols="12">
            <b-form-group label="Nombre completo" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-first-name"
                  v-model="selectedBilling.nombre"
                  placeholder="Nombre completo"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <b-form-group label="Email" label-for="vi-email">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-email"
                  type="email"
                  v-model="selectedBilling.correo"
                  placeholder="Email"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- mobile -->
          <b-col cols="12">
            <b-form-group label="Telefono" label-for="vi-mobile">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SmartphoneIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-mobile"
                  type="number"
                  v-model="selectedBilling.telefono"
                  placeholder="Telefono"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- id type-->
          <b-col cols="12" md="5">
            <b-form-group label="Tipo" label-for="vi-id-type">
              <v-select
                id="vi-id-type"
                label="title"
                v-model="selectedBilling.tipoidentifica"
                :reduce="(t) => t.code"
                :options="tiposIdentificacion"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- id -->
          <b-col cols="12" md="7">
            <b-form-group label="Identificacion" label-for="vi-id">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-id"
                  type="number"
                  v-model="selectedBilling.identificacion"
                  placeholder="Identificacion"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Status -->
          <b-col cols="12">
            <b-form-group label="Estado" label-for="vi-status">
              <v-select
                id="vi-status"
                label="title"
                v-model="selectedBilling.estado"
                :reduce="(e) => e.code"
                :options="estados"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- submit -->
          <b-col cols="12">
            <b-button block variant="primary" class="mr-1">
              <b-spinner v-if="saving" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BBadge,
  BButton,
  BSpinner,
  BDropdown,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BDropdownItem,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    vSelect,
    BSpinner,
    BDropdown,
    BFormGroup,
    BFormInput,
    BInputGroup,
    VueGoodTable,
    BDropdownItem,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  props: {
    clientID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      loading: true,
      estados: [
        { title: "Activo", code: "A" },
        { title: "Inactivo", code: "I" },
        { title: "Eliminado", code: "E" },
      ],
      tiposIdentificacion: [
        { title: "Cedula", code: "C" },
        { title: "Pasaporte", code: "P" },
        { title: "RUC", code: "R" },
      ],
      columns: [
        {
          label: "Identificacion",
          field: "identificacion",
        },
        {
          label: "Nombres",
          field: "nombre",
        },
        {
          label: "En uso",
          field: "predeterminada",
        },
        {
          label: "Acciones",
          field: "action",
        },
      ],
      cabeceras: [],
      searchTerm: "",
      selectedBilling: {},
    };
  },
  mounted() {
    this.getBillings();
  },
  methods: {
    editBilling(event, billing) {
      event && event.preventDefault();
      this.selectedBilling = billing;
    },
    confirmDeleteBilling(event, billing) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Desea eliminar la direccion?.", {
          size: "sm",
          title: "Confirmacion",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.deleteBilling(billing);
          }
        });
    },
    getBillings() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/Cabeceras",
          body: JSON.stringify({
            empresa: 2,
            cliente: this.clientID,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.cabeceras = res.data.cabeceras;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.error = e.message;
          this.loading = false;
        });
    },
    deleteBilling(billing) {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UserStores", //TODO: Implementar API
          body: JSON.stringify({
            selectedBillingID: billing.id,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se agrego correctamente al Blacklist`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
          } else {
            this.$toast({
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al agregar a Blacklist (${res.data.mensaje}).`,
                title: "Error",
                variant: "danger",
              },
              component: ToastificationContent,
            });
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al agregar a Blacklist (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        });
    },
  },
  computed: {
    defaultText() {
      const status = {
        S: "Si",
        N: "No",
      };
      return (s) => status[s];
    },
    defaultVariant() {
      const statusColor = {
        S: "light-success",
        N: "light-secondary",
      };
      return (s) => statusColor[s];
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>